<template>
  <EditView />
</template>

<script>
import EditView from '@/components/EditView';

export default {
  components: {
    EditView
  }
};
</script>
